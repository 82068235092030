<template>
	<!-- style="transform:rotate(90deg)" -->
	<div id="app" >
		<el-container>
			<el-header direction='horizontal' style="padding:0px">
				<layoutTop :balance_trx="balance_trx" :balance_usdt="balance_usdt" :balance_win_total="balance_win_total" @transferuser_type="transferuser_type" @transferLogin="transferLogin"></layoutTop>
			</el-header>
			<el-main style="padding:0px">
				<div class="main">
					<keep-alive>
						<router-view ></router-view>
					</keep-alive>
				</div>
			</el-main>
			<!-- <el-footer style="padding:0px">
				<layoutFoot  ></layoutFoot>
			</el-footer> -->
		</el-container>
	</div>
</template>

<script>
	import request from '@/utils/request';
	import layoutTop from './component/layout/top'
	import layoutFoot from './component/layout/footer'

	export default {
		name: 'App',
		components: {
			layoutTop,
			layoutFoot,

		},
		data() {
			return {
				balance_trx: 0,
				balance_usdt: 0,
				balance_btc: 0,
				balance_win_total:0,
				balance_p_trx:0,
				balance_p_usdt: 0,
				balance_p_btc: 0,
				balance_p_win_total:0,

				//z_type: 2,
				f_type: 2,

				socket_msg_callback: '', //回调
				socket_msg_top_callback: '', //回调
				socket_msg_foot_callback: '', //回调
				socket_connect: false, //连接状态
				reconnection_num: 0, //重连次数

			}
		},
		mounted(){
			
		},
		created() {
			let _this = this;
			if (sessionStorage.getItem('token')) {
				if(window.sessionStorage.getItem('balance_usdt')){
					if(window.sessionStorage.getItem('balance_usdt') == 'undefined'){
						_this.type = 2
						window.sessionStorage.setItem('type',2)
					}else{
						_this.balance_usdt = window.sessionStorage.getItem('balance_usdt')
					}
				}
			}
		},
		//离开路由之后断开websocket连接
		destroyed() {
			
		},
		methods: {
			
			transferuser_win_total(msg) {
				//子组件game 传值给父组件app
				this.balance_p_win_total = msg;
				//父组件app 传值给子组件top
				this.balance_win_total = this.balance_p_win_total;
			},
			transferuser_trx(msg) {
				//子组件game 传值给父组件app
				this.balance_p_trx = msg;
				window.sessionStorage.setItem('balance_trx', this.balance_p_trx);
				//父组件app 传值给子组件top
				this.balance_trx = this.balance_p_trx;
			},
			transferuser_usdt(msg) {
				//子组件game 传值给父组件app
				this.balance_p_usdt = msg;
				window.sessionStorage.setItem('balance_usdt', this.balance_p_usdt);
				//父组件app 传值给子组件top
				this.balance_usdt = this.balance_p_usdt;
			},

			transferuser_type(msg) {
				//子组件 传值给父组件app
				this.f_type = msg;
				window.sessionStorage.setItem('type', this.f_type);
				//父组件app 传值给子组件
				//this.z_type = this.f_type;
			},
			//登录赋值余额
			transferLogin(msg){
				//子组件game 传值给父组件app
				this.balance_p_trx = msg.balance_trx;
				this.balance_p_usdt = msg.balance_usdt;
				window.sessionStorage.setItem('balance_trx', this.balance_p_trx);
				window.sessionStorage.setItem('balance_usdt', this.balance_p_usdt);
				//父组件app 传值给子组件top
				this.balance_trx = this.balance_p_trx;
				this.balance_usdt = this.balance_p_usdt;
				
			},


		}

	}
</script>

<style lang="less" scoped>

	#app {

		.main {
			color: #ffffff;
			// font-family: "arial";
			font-family: ubuntu,sans-serif;
			font-size: 16px;
			-webkit-font-smoothing: antialiased;

		}

	}
	input[type=number] {
	    -moz-appearance:textfield;  
	}  
	input[type=number]::-webkit-inner-spin-button,  
	input[type=number]::-webkit-outer-spin-button {  
	    -webkit-appearance: none;  
	    margin: 0;  
	}


</style>
