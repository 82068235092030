import axios from 'axios'
import {
	Loading
} from 'element-ui';

// 创建axios实例
const HTTP_URL = 'https://api.blockmoneychain.com/'
const service = axios.create({
	baseURL: HTTP_URL, // api的base_url
	timeout: 30000, // 请求超时时间
	headers: {
		'content-type': 'application/json',
		// 'Connection': 'keep-alive',
		// token: sessionStorage.getItem('token')

	},
})


service.interceptors.request.use(
	config => {
		config.headers.auth = sessionStorage.getItem('token');
		return config  // 对config处理完后返回，下一步将向后端发送请求
	},
	error => {  // 当发生错误时，执行该部分代码
	    console.log(error); //调试用
	    return Promise.reject(error)
	}
)

// let is_router = false;
// service.interceptors.response.use(
// 	response => {
// 		const res = response.data;  // 该处可将后端数据取出，提前做一个处理
// 		if (res.code == 9999 && is_router == false) {
// 			is_router = true;
// 			alert('请登录');
// 			window.location.href="/#/index";
// 			// return Promise.reject('11111111111')
// 		}else{
// 			return response;
// 		}
// 	},
// 	error => {  // 当发生错误时，执行该部分代码
// 	    console.log(error); //调试用
// 	    return Promise.reject(error)
// 	}
// )

export default service
