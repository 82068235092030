<template>
	<div class="header">
		<div style="display: flex;justify-content:space-between;align-items:center;margin: 0 10px;">	
			
			<div class="" style="display: flex;justify-content: flex-start;align-items: center;">
				<!-- logo -->
				<router-link to="/index" style="display: flex;align-items: center;">
					<img src="../../assets/images/blockmoney/logo.png" style="height: 27px;" >
				</router-link>
				
			</div>

			
			<div style="display: flex;justify-content:flex-end;align-items:center;height: 60px;">
	
				<!-- 登录 钱包-->
				
				<div v-if="hasLogin == 1" style="display: flex;justify-content:flex-end;align-items:center;height: 60px;">
					
						
						<div class="kemLHJ" @click="touser()">{{user_info.username}}</div>
						
					
				</div>
				<!-- 未登录 -->
				<div v-else style="display: flex;justify-content:flex-end;align-items:center;height: 60px;">
					
						<router-link to="/login" >
						<div class="kemLHJ" >{{$t('top.Login')}}</div>
						</router-link>
					
					<!-- <button class="dIRXkY" scale="sm" style="margin-left: 5px;">
						<router-link to="/register" >
						<div class="kemLHJ" >{{$t('top.Sign Up')}}</div>
						</router-link>
					</button> -->
					
				</div>
			</div>
			
		</div>
				
		<!-- 弹出遮罩start -->
		<div class="modal" v-if="show_modal">

		</div>
		<!-- 弹出遮罩end -->


		

	</div>
</template>

<script>
	import request from '@/utils/request';

	export default {
		computed: {},
		components: {},
		data() {
			return {
				activeIndex: '1',
				activeIndex2:'1',
				hasLogin: 0,
				showMenu: {
					isShow: 0,
					mode: 'horizontal'
				},
				show_modal: false,
				show_model_value:0,
				centerDialogVisible: true,
				
				amount: 0, //充值金额
				allamount: 0,
				allamount_text: '',
				address: '', //钱包地址
				rechargeaddress:'',
				top_timer:0,
				type:2,//币种 1=trx 2=usdt
				dropdown_inner:false,
				show_balance_opened:false,
				loading:'',
				yuer_balance_trx:'',//账户余额
				invitecode:'',//邀请码
				message:'',//邀请码链接
				language_item_name:'English',
				language_item_name_list:[
					{id:1,'name':'English','value':'en','img':'assets/style/i18n/1.png'},
					{id:2,'name':'中文','value':'zh','img':'/assets/style/i18n/2.png'}
				],
				
				user_info:[],
				
				
			};
		},
		filters: {
			numFilterusdt(value) {
				// 截取当前数据到小数点后两位
				let realVal = parseFloat(value).toFixed(2);
				return realVal;
			}
		},
		props: {
			balance_trx: {
				default: 0
			},
			balance_usdt: {
				default: 0
			},
			balance_win_total: {
				default: 0
			},
			
		},
		mounted() {
			let _this = this;

		},
		activated() {
		    console.log('top我这个页面显示就会执行');
			let _this = this;
		},
		created() {
			let _this = this;
			
			if (window.sessionStorage.getItem('language_item_name') == 'en') {
				_this.language_item_name = 'English'
				this.$i18n.locale = 'en';
			}else if(window.sessionStorage.getItem('language_item_name') == 'zh'){
				_this.language_item_name = '中文'
				this.$i18n.locale = 'zh';
			}else{
				_this.language_item_name = '中文'
				this.$i18n.locale = 'zh';
			}
			
			if(window.localStorage.getItem('handleselect')>0){
				_this.activeIndex2 = window.localStorage.getItem('handleselect')
			}
			
			console.log(window.sessionStorage.getItem('token'))
			let token = window.sessionStorage.getItem('token');
			if (token == null || token == '' || token == 'null' || token == undefined || token == 'undefined') {
				this.hasLogin = 0;
				window.sessionStorage.clear();
			}else{
				this.hasLogin = 1;
				//_this.$props.balance_win_total = 1;
				_this.invitecode = window.sessionStorage.getItem('invitecode')
				_this.getuser()
			}
			
			
			
			
			
		},
		methods: {
			//获取用户信息
			getuser(){
				let _this = this;
				let token = window.sessionStorage.getItem('token');
				request.post('finance/user/index', {
					token: token,
				}).then(res => {
					//console.log(res)
					//结果
					if(res.data.code ==0){
						window.sessionStorage.setItem('user_info',JSON.stringify(res.data.data));
						//console.log(res.data.data.user_info)
						_this.user_info = res.data.data
					}else if(res.data.code == -2 ){
						window.sessionStorage.clear();
						this.$router.replace({ name: 'Login', params: { refresh: true }})
					}else{
						_this.$message({
						    message: res.data.msg,
						    type: 'warning'
						});
					}
				});
			},
			// 复制邀请码
			copyCode() {
				let _this = this;
				let invitecode = _this.invitecode;
				let url = 'http://www.blockmoneychain.com/#/index?code='+invitecode;
				//console.log(url)
				_this.$copyText(url).then(
					res => {
					    //console.log(res)
						_this.$message({
						    message: 'Copy Success',
						    type: 'success'
						});
					},
					err => {
					    this.$message.error('error');
					})
			},


			//点击改变i18n
			clilanguageitem(item){
				console.log("语言")
				let _this = this;
				_this.language_item_name = item.name;
				window.sessionStorage.setItem('language_item_name',item.value)
				this.$i18n.locale = item.value;
				let token = window.sessionStorage.getItem('token');
				
				//_this.$emit('socket_send_foot',msg_data);

			},
			
			

			//获取邀请码
			getInviteCode(){
				let _this = this;
				//查询用户信息接口
				let token = window.sessionStorage.getItem('token');
				
				request.post('user/getInviteCode', {
					token: token,
				}).then(res => {
					
				});
			},
			
			touser(){
				this.$router.replace({ name: 'User', params: { refresh: true }})
			},
	

			
			

			

		}
	}
</script>
<style lang="less">


	/* 按钮边框去掉 */
	button:focus{outline: none}

	.header { 
		width: 100%;
		// background:url(../../assets/images/blockmoney/header.png) ;
		background-size:100vw 60px;
		background-color: #0A3D6F;
		// background-repeat:no-repeat;
		// height: 60px;
		// line-height: 60px;
		// color: #fff;
		// border-bottom: 2px solid #70CAE5;
		.nav-menu {
			text-align: left;
			color: #fff;
			.el-icon-menu:before {
				font-size: 40px;
			}
		}
		.logo {
			text-align: left;
			.img-logo {
				width: 249px;
				height: 54px;
			}
		}
		.img-logo2 {
			display: none;
			text-align: center;
			height: 35px;
			margin-right: 50px;
		}
		@media(max-width:500px) {
			.img-logo2 {
				display: block;
				text-align: center;
				height: 35px;
				margin-right: 50px;
			}
		}
		.sign {
			text-align: right;
			// padding-top: 15px;
		}
	

		.btn-sign {
			background: #f9e716;
			border: 1px solid #f9e716;
			color: #52371b;
			font-weight: bold;
			
			font-size: 12px;
			border-radius: 3px;
			padding: 0px 15px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			vertical-align:middle ;
			margin-right: 10px;
			
		}
		.btn-login {
			background: #52371b;
			border: 1px solid #52371b;
			color: #ffffff;
			font-weight: bold;
			
			font-size: 12px;
			border-radius: 3px;
			padding: 0px 15px;
			height: 35px;
			line-height: 35px;
			text-align: center;
			
		}
		
	}
	.e-input {
		display: block;
		width: 100%;
		padding: 10px 15px;
		border: 1px solid rgba(85, 101, 126, .5);
		border-radius: 12px;
		height: 52px;
		line-height: 52px;
		background-color: transparent;
		color: #fff;
		font-size: 15px;
		transition: all .3s;
		outline: none;
	}
	




input[type=number] {
	    -moz-appearance:textfield;  
	}  
	input[type=number]::-webkit-inner-spin-button,  
	input[type=number]::-webkit-outer-spin-button {  
	    -webkit-appearance: none;  
	    margin: 0;  
	}
</style>
