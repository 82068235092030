import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  // vue去掉路由的井号
  //mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/index'),
      redirect:'/index'
    },
    {
      path: '/index',
      name: 'Index',
      component: () => import('@/views/index'),
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('@/views/account'),
    },
	{
	  path: '/information',
	  name: 'Information',
	  component: () => import('@/views/information'),
	},
	{
	  path: '/login',
	  name: 'Login',
	  component: () => import('@/views/login'),
	},
	{
	  path: '/register',
	  name: 'Register',
	  component: () => import('@/views/register'),
	},
	{
	  path: '/user',
	  name: 'User',
	  component: () => import('@/views/user'),
	},
	{
	  path: '/user_withdraw',
	  name: 'User_withdraw',
	  component: () => import('@/views/user_withdraw'),
	},
	{
	  path: '/robotedit',
	  name: 'Robotedit',
	  component: () => import('@/views/robotedit'),
	},
	{
	  path: '/depositList',
	  name: 'DepositList',
	  component: () => import('@/views/depositList'),
	},
	{
	  path: '/new_file',
	  name: 'New_file',
	  component: () => import('@/views/new_file'),
	},

  ]
})

export default router
