<template>
    <!-- <div class="footer" > -->
        <!-- <div class="logo"><img src="@A/images/bottom_logo.png" class="img-logo" /></div> -->
        <!-- <div class="tabgger"> -->
        	<!-- <div class="tabgger_div">
				<router-link to="/index" class="tabgger_div">
					<img src="../../assets/images/coinrich/home_light.png" >
					<div>{{$t('footer.Home')}}</div>
				</router-link>
        		
        	</div> -->
			
			<!-- <div class="tabgger_div">
				<router-link to="/information" class="tabgger_div">
					<img src="../../assets/images/coinrich/sponsor.png" >
					<div>{{$t('footer.Trade')}}</div>
				</router-link>
			</div> -->
			<!-- <div class="tabgger_div">
				<router-link to="/user" class="tabgger_div">
					<img src="../../assets/images/coinrich/my_light.png" >
					<div>{{$t('footer.My')}}</div>
				</router-link>
			</div> -->
        <!-- </div> -->
		
    <!-- </div> -->
</template> 

<script>

export default {
    computed: {
    },
    components: {
    },
    data() {
        return {

        };
    },
	activated() {
	    //console.log('我这个页面显示就会执行');
		let _this = this;

	},
	mounted() {
		let _this = this;

	},
    methods:{


		
		

    }
}
</script>
<style lang="less" scoped>

.footer{
    display: none;
}
@media(max-width:500px) {
    .footer{
		display: block;
        position: fixed;
    	bottom: 0;
        width: 100%;
        height: 60px;
        background-color: #1b2233;
    	z-index: 999999;/*这里是该元素与显示屏的距离，据说越大越好，但是我也没有看到效果，因为没有它也是可以的*/
    	-webkit-transition: .5s ease-in-out;/* css的transition允许css的属性值在一定的时间内从一个状态平滑的过渡到另一个状态 */
    	-moz-transition: .5s ease-in-out;/*这里为了兼容其他浏览器*/
    	-o-transition: .5s ease-in-out;
    }
}
.tabgger{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}
.tabgger_div{
	width: 33.3%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.tabgger_div img{
	margin-bottom: 5px;
}
.tabgger_div div{
	color: #b8add2;
}
	


</style>
