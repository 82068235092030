import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'
import './assets/styles/base.css'

import LangEn from './assets/VueI18n/language-en.json'
import LangZh from './assets/VueI18n/language-zh.json'

// import VueSocketIO from 'vue-socket.io'
import VueClipboard from 'vue-clipboard2'


import 'echarts'
import ECharts from 'vue-echarts'
Vue.component('v-chart', ECharts)
import 'echarts/theme/dark-fresh-cut'

Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.config.productionTip = false
//引用i18n
import VueI18n from 'vue-i18n';
Vue.use(VueI18n) // 通过插件的形式挂载，通过全局方法 Vue.use() 使用插件
const i18n = new VueI18n({
	locale: 'zh', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
	messages:{
	 'zh': LangZh,
	 'en': LangEn
	}
});  

new Vue({
  el: '#app',  
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
